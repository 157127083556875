/* Contêineres Gerais */
.container-row {
  margin: 5px;
}

.container-col {
  background-color: #fff;
  border-radius: 5px;
}

/* Botões e Ícones */
.button-container {
  display: contents;
}

.icon-style {
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  color: #00cc66;
}

.span-text {
  margin-left: 10px;
}

.button-style {
  width: 110%;
  margin-bottom: 5px;
}

/* Menu Lateral */
.menu-toggle-card {
  width: 50px;
  height: 50px;
  left: 0;
  color: #fff;
}

.menu-icon {
  color: #00cc66;
  font-size: 20px;
}

/* Estilos Específicos para Projeção de Vendas */
.sales-projection-container {
  margin-top: 50px;
  background-color: #23d978;
  padding: 10px;
  text-align: center;
}

.sales-projection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sales-projection-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sales-projection-title-text {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

.report-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}
.report-grid-2 {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.report-grid-simple {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.report-item {
  flex: 1 1 20%;
  max-width: 232px;
  min-width: 150px;
}

.report-card {
  margin: 5px;
}

.report-card-title {
  font-size: 16px;
  font-weight: bold;
}

.report-card-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.report-card-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #000;
}

.report-card-statistic {
  color: #3f8600;
}

/* Filtros */
.filters-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-card {
  min-width: 130px;
  margin: 5px;
}

.filter-select {
  width: 120px;
}

/* Gráficos */
.chart-card {
  min-width: 300px;
  height: 580px;
  margin-right: 5px;
}
.chart-card-1 {
  width: 33%;
  min-width: 350px;
  height: 375px;
  margin-right: 5px;
}

.chart-card-Transition {
  width: 50%;
  min-width: 50%;
  height: 580px;
  margin-right: 5px;
}

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Tabela */
.table-container {
  width: 86.3vw;
  margin-top: 3px;
}

/* iFrame */
.iframe-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.iframe-style {
  border: none;
  height: 87vh;
  width: 100%;
}

/* Estilos Gerais */
.card-menu-toggle {
  border: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 9999;
  left: 0;
  background-color: #fff;
}

.main-container {
  margin-top: 1px;
  background-color: #23d978;
  padding: 10px;
  text-align: center;
}

.header-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-title-sub {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-container {
  border: 10px solid #23d97895;
  width: 100%;
  margin-top: 5px;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.card-statistic {
  margin: 5px;
  width: 100%;
  height: 80px;
}
.card-statistic .ant-card-body {
  padding: 5px !important;
}

.card-growth {
  margin: 5px;
}

.card-title {
  font-weight: bold;
}

.statistic-value {
  color: #3f8600;
}

.card-filter {
  width: 100%;
  margin: 5px;
  height: 80px;
}
.card-filter .ant-card-body {
  padding: 5px !important;
}
.card-filter2 {
  width: 100%;
  margin: 5px;
  height: 100px;
}
.card-filter2 .ant-card-body {
  padding: 5px !important;
}

.span {
  font-weight: bold;
  color: #fff;
}
.chart-card-2 {
  min-width: 300px;
  height: 375px;
  margin-right: 5px;
}

.ant-breadcrumb-link {
  font-weight: bold;
}
