/* Contêineres Gerais */
.container-row {
  margin: 5px;
}

.container-col {
  background-color: #fff;
  border-radius: 5px;
}

/* Botões e Ícones */
.button-container {
  display: contents;
}

.icon-style {
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  color: #00cc66;
}

.span-text {
  margin-left: 10px;
}

.button-style {
  width: 100%;
  margin-bottom: 10px;
}

/* Menu Lateral */

.menu-icon {
  color: #00cc66;
  font-size: 20px;
}

/* Estilos Específicos para Projeção de Vendas */
.sales-projection-container {
  margin-top: 50px;
  background-color: #23d978;
  padding: 10px;
  text-align: center;
}

.sales-projection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sales-projection-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sales-projection-title-text {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}


.report-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}
.report-grid-2 {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.report-item {
  flex: 1 1 20%;
  max-width: 232px;
  min-width: 150px;
}

.report-card {
  margin: 5px;
}

.report-card-title {
  font-size: 16px;
  font-weight: bold;
}


.report-card-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #000;
}

.report-card-statistic {
  color: #3f8600;
}

/* Filtros */
.filters-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-card {
  min-width: 130px;
  margin: 5px;
}

.filter-select {
  width: 120px;
}

/* Gráficos */

.divtimer{
  position: fixed;
  top: 50px;
  right: 10px;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
background-color: #23d97885;
border-radius: 8px;
}
.time{
  margin: 10px;
  padding: 10px;
  color: #000;
  font-weight: bold;
}
/* Tabela */
.table-container {
  width: 86.3vw;
  margin-top: 3px;
}

/* iFrame */

.iframe-style {
  border: none;
  height: 87vh;
  width: 100%;
}

/* Estilos Gerais */
.card-menu-toggle {
  border: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 9999;
  left: 0;
  background-color: #fff;
}


.header-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.border-container {
  border: 10px solid #23d97895;
  width: 100%;
  margin-top: 5px;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}


.card-growth {
  margin: 5px;
}

.card-title {
  font-weight: bold;
}

.statistic-value {
  color: #3f8600;
}
